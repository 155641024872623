.container {
  position: relative;
  height: 100vh;
}

.image-container {
  position: relative;
  text-align: center;
  display: flex;
}

.background-image {
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  display: none;
  margin: 0 auto; /* Center the image horizontally */
}

.overlay-container {
  position: absolute;
  /* top: 10%;
  left: 10%; */
  /* transform: translate(-25%, -25%); */
}

@media print {
  *,
  *:before,
  *:after {
    background: rgba(0, 0, 0, 0) !important;
    color: rgb(0, 0, 0) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .background-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: auto;
    z-index: -1; /* Send the image to the background */
  }

  .overlay-table {
    /* background: none; Remove background during printing */
    position: relative;
    z-index: 1; /* Bring the table to the front */
  }
  .bg {
    margin: 0px !important;
  }

  .bg2 {
    margin-top: 15px !important;
  }
  .res {
    margin-top: 125px;
    padding: 60px;
    height: 85vh;
  }
  .res td .res2 {
    margin-top: 150px;
    /* padding: 60px; */
    height: 85vh;
  }

  .table td,
  th {
    padding: 5px !important;
  }

  .padding td,
  th {
    padding: 3px !important;
  }

  .marks td,.marks th {
    padding: 0px !important;
  }
}

.overlay-table {
  page-break-before: always;
}

/* If you want to remove the extra page break after the last table */
.overlay-table:last-child {
  page-break-after: auto;
}

table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

.bgimg {
  background-image: url("tablebg.jpg");
}
.date {
  position: absolute;
  bottom: 0px;
  left: 170px;
}
.datere {
  position: absolute;
  bottom: -390px;
  left: 120px;
}

.daterep {
  position: absolute;
  bottom: -180px;
  left: 120px;
}
.engdate {
  position: absolute;
  bottom: 25px;
  left: 170px;
}

.coesign {
  position: absolute;
  bottom: 0px;
  left: 730px;
}

.coesignre {
  position: absolute;
  bottom: -390px;
  left: 690px;
}
.coesignrep {
  position: absolute;
  bottom: -180px;
  left: 690px;
}


.table td,
.tabel th {
  vertical-align: middle !important;
  padding: 10px !important;
}
td p {
  vertical-align: middle !important;
}
td p b{
  vertical-align: middle !important;
}
.result td,
.result th {
  vertical-align: middle !important;
  font-size: 13px !important;
  padding: 4px !important;
}

.marks th,.marks th {
  vertical-align: middle !important;
  font-size: 12px !important;
  padding: 0px !important;

}
.result_all td,
.result_all th {
  vertical-align: middle !important;
  font-size: 12px !important;
  padding: 2px !important;
}

.reportmarkstable td,
.reportmarkstable th {
  padding: 0.3rem !important;
}

.program span{

  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}