.Topbar{
    #page-topbar{
        position: static;
    }
    .cursor-pointer{
        cursor: pointer !important;
    }
    .dropdown-menu{
        z-index: 9999;
    }


    @media screen and (max-width:988px) {
        .w-sm-100{
            width: 100%;
        }
    }

    .collegeOptSelect{
        text-align: right;
        option{
            text-align: left;
    }
    }
}